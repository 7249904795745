.defaultButton {
  all: unset;
  cursor: pointer;
  width: calc(337px + 100vw - 1024px) !important;
  height: 72px;
  left: 135px;
  top: 732px;
  background: #8e85ff;
  border-radius: 16px;
  font-family: "Articulat CF Medium";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */
  text-align: center;
  color: #ffffff;
}

.walkThroughNext {
  margin-left: 30px;
  display: inline-flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 19px;
  border: 2px solid #d3c2ff;
}

.walkThroughButton {
  margin-right: 30px;
  display: flex;
  width: 86px;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  border: 1px solid rgba(46, 46, 46, 0.85);
}
