.CConfigCongurationArea {
  display: flex;
  flex-direction: row;
}

.CConfigLeftArea {
  border-radius: 12px;
  background-color: #313131;
}

.CConfigRightArea {
  width: 60%;
  background-color: #313131;
}

.CConfigButtonArea {
  height: 96px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
}

.CConfigButtonContainer {
  height: 48px;
  display: flex;
}

.CConfigButtonContainerSpinner {
  display: flex;
  justify-content: end;
  padding: 20px 170px 20px 20px;
}

.ConnectorConfigPageColumn {
  @media (min-width: 768px) {
    height: calc(100vh - 14.583rem);
  }

  &.addFlex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
