/* 
* Profile Card main container
*/
.profileCardMainContainer {
  background-color: rgba(49, 49, 49, 1);
  height: auto;
  padding: 12px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.profileCardProfileArea {
  display: flex;
  align-items: center;
}

.profileCardProfileIcon {
  margin: 0px 12px 16px 0px;
}

.profileCardProfileText {
  color: white;
  font-size: 18px;
}

.profileCardDescription {
  color: white;
  font-size: 14px;
  margin-bottom: 12px;
}

.profileCardCreateDate {
  color: white;
  font-size: 14px;
  margin-bottom: 12px;
}

.profileCardPipelineCount {
  color: white;
  font-size: 14px;
  margin-bottom: 12px;
}

.profileCardSettingsArea {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
}

.profileCardSettingsIcon {
  height: 16px;
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

.profileCardSettingsText {
  color: white;
  font-size: 14px;
  margin: 0;
}

.workspaceCardContainer {
  background-color: rgba(49, 49, 49, 1);
  height: auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: 12px;
}
