/*
    Table of Contents
    Name                 : Condense
    Author               : Prithvi Kumar
    
    Table of Sass Contents
    1. Abstracts
    2. Base
    3. Components
    4. Layouts
    5. Pages
*/

////////////////////
// Abstracts
////////////////////

////////////////////
// Base
////////////////////
@import "./base/base";

////////////////////
// Components
////////////////////
@import "./components/components";

////////////////////
// Layouts
////////////////////
@import "./layout/layout";

////////////////////
// All pages
////////////////////
@import "./pages/pages";

////////////////////
// Version Color
////////////////////
// NOTE: Dark Mode should be defined in layouts.

/* Base Styling */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
