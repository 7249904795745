/* 
! Main Navigation Bar Style 
*/
.navBarStyle {
  background: #2f2f2f;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
  /* 
!  Navbar Top Area
*/
  .navBarTopRow {
    border-bottom: 1px solid #4b4b4b;
    display: flex;
    justify-content: space-between;
    padding: 0.8rem 0.81rem;
    height: 3.83rem;
  }
  .navBarleftArea {
    display: flex;
    gap: 60px;
    align-items: center;
  }

  .navBarLogoArea {
    display: flex;
    align-items: center;

    .navBarCondenseLogo {
      cursor: pointer;
      min-width: 100px;
    }

    img {
      max-width: 100%;
      height: 1.8rem;
      width: 8rem;
    }

    .navBarWorkSpaceParent {
      display: flex;
      align-items: center;
    }
    .navBarWorkspaceLine {
      height: 1.8rem;
      border: 1px solid grey;
    }

    .navBarWorkspaceLogo {
      width: 1.8rem;
    }

    .navBarWorkspaceName {
      color: #f6f6f6;
      font-size: 1.112rem;
      margin: 0px 0.4rem 0px 0.6rem;
    }

    .navBarWorkspaceDropDown {
      display: flex;
      align-items: center;

      img {
        cursor: pointer;
        height: 1.3rem;
        width: 1.3rem;
      }
    }
  }

  .navBarRightAreaParent {
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }

  .navBarProfileArea {
    display: flex;
    align-items: center;
  }
  .rightProfileArea {
    display: flex;
    align-items: center;
  }

  .navBarProfileAvatar {
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;
    background-color: whitesmoke;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 0.2rem 0px 0px;

    p {
      color: black;
      font-size: 0.94rem;
      font-weight: bold;
      margin: 0;
      font-family: "Articulat CF Medium";
    }
  }

  .navBarProfileDownArrow {
    display: flex;
    align-items: center;

    img {
      cursor: pointer;
      height: 1.3rem;
      width: 1.3rem;
    }
  }

  /* 
!  Navigation Bar "Free Plan" Card
*/

  .navBarFreePlanCard {
    background-color: #4ac5ce31;
    border: 1px solid #47ccd6;
    border-radius: 4px;
    cursor: pointer;
    padding: 2px 8px 2px 8px;
  }

  .navBarCardContent {
    color: #47ccd6;
    font-weight: bold;
    font-size: 12px;
    line-height: 18.2px;
    align-items: center;
  }

  .navBarCardIcon {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .navBarFreePlanIcon {
    height: 12px;
    width: 12px;
    margin-top: 2px;
  }

  /* 
!  Navigation Bar Right Icons Area
*/
  .navBarRightArea {
    display: flex;
    gap: 16px;
  }
  .navBarBright {
    cursor: pointer;
  }

  .navBarWebStory {
    cursor: pointer;
  }

  .navBarNotification {
    cursor: pointer;
  }

  /* 
!  Logout Dropdown Modal Styling
*/
  .accountListDropDown {
    margin-top: 20px;
    border-radius: 8px;
    padding: 10px;
    background-color: rgba(49, 49, 49);
    box-shadow: 0 5px 10px rgba(23, 23, 23, 0.1);
    max-height: 220px;
    min-width: 180px;
    overflow: hidden;
    /* 
  !  Navigation Bar Account Drop Down Text
*/
    .accountListText {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 240px;
      height: 206px;
      cursor: pointer;
    }
  }

  /* 
  !  Navigation Bar Logout Dropdown Position
*/
  .navBarAccountDropDownContainer {
    position: relative;
  }

  .navBarAccountDropDownContent {
    position: absolute;
    top: calc(100% + 10px);
    left: -150px;
    z-index: 1;
  }

  /* 
  !  Navigation Bar Workspace Drop Down Modal Style
*/
  .workSpaceDropDownContainer {
    background: rgba(39, 39, 39);
    padding: 10px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    width: 18rem;
    border-width: 2px; /* Specify the border width */
    border-style: solid; /* Specify the border style */
    border-color: white;
    border-radius: 12px;
  }

  .workSpaceDropDownItem {
    cursor: pointer;
    display: flex;
    margin: 10px 0px;
  }

  .workSpaceDropDownButton {
    margin: 10px 0px;
    height: 3rem;
    width: 100%;
    border: 1px solid rgba(250, 250, 250, 1);
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .workSpaceDropDownButtonContent {
    display: flex;
    flex-direction: row;
  }

  .workSpaceDropDownButton p {
    color: white;
    margin-top: 1rem;
    font-size: 18px;
    margin-left: 2rem;
    padding: 0;
  }

  .workSpaceDropDownButtonImage {
    height: 1.2rem;
    width: 1.2rem;
    margin-top: 1rem;
    margin-left: 0.2rem;
  }

  /* 
  !  NavigationBar Workspace Drop Down Modal Position
*/

  .workSpaceDropDownContainerPosition {
    position: relative;
  }

  .workSpaceDropDownContentPosition {
    position: absolute;
    top: calc(100% + 27px);
    z-index: 2;
  }
}

.navBarCommonDropDownContainer {
  position: absolute;
  top: 110px;
  z-index: 1;
  width: 204px;
  height: 238px;
  border: 1px solid rgba(250, 250, 250, 0.24);
  background: rgba(39, 39, 39, 0.7);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(8px);
  overflow-y: auto;
}

.navBarCommonDropDownContainer::-webkit-scrollbar {
  width: 4px;
  background-color: rgba(0, 0, 0, 0.7);
}

.navBarCommonDropDownContainer::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
}

.navBarCommonDropDownContainer::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.6);
}

.navBarCommonDropDownEachContainer {
  height: 48px;
  border-bottom: 1px solid grey;
  padding: 12px;
  cursor: pointer;
}

.navBarCommonDropDownIcon {
  height: 24px;
  width: 24px;
  margin-right: 12px;
  background-color: red;
}
.navBarCommonDropDownContainer p {
  color: white;
  font-size: 14px;
}
