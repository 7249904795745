.codeEditorFormSelect {
  background-color: black !important;
  color: white !important;
}

.codeEditorFormSelectDisabled {
  background-color: grey !important;
  color: white !important;
}

.codeEditorCommitButton {
  background-color: black !important;
  color: white !important;
}

.codeEditorCommitButtonDisabled {
  background-color: grey !important;
  color: white !important;
}

.codeEditorGitButtonBackground {
  background-color: black !important;
  color: white !important;
}

.custom-modal .modal-content {
  background-color: #36454f;
  color: white;
}

.custom-modal .modal-header {
  background-color: #36454f;
  color: white;
}

.custom-modal .modal-footer {
  background-color: #36454f;
  color: white;
}

.custom-modal .btn-primary {
  background-color: white;
  color: black;
}

.custom-modal .btn-secondary {
  background-color: white;
  color: black;
}

.custom-modal .modal-content .btn-close {
  background-color: white;
}

.codeEditorMainCol {
  background-color: #171717;
  height: 60vh;

  .codeEditorMainRow {
    width: 100%;

    .codeEditorFolderTree {
      background-color: #222122;
      padding-bottom: 15px;
      height: 60vh;
      overflow: scroll;
    }

    .codeEditorEditorArea {
      background-color: #1c1c1c;
      height: 60vh;
    }

    .codeEditorTabs1 {
      background-color: #3c3c3c;
      color: white;
      border: none;
      border-left: 1px solid grey;
      border-block-end: grey;
      min-width: 120px;
    }

    .codeEditorTabs2 {
      background-color: #1c1c1c;
      color: white;
      border: none;
      border-left: 1px solid grey;
      border-block-end: grey;
      min-width: 120px;
    }

    .codeEditorTabClose1 {
      background-color: #3c3c3c;
      border-right: 0.5px solid grey;
      color: white;
      border: none;
    }

    .codeEditorTabClose2 {
      background-color: #1c1c1c;
      border-right: 0.5px solid grey;
      color: white;
      border: none;
    }
  }

  .folderTreeAreaTop {
    margin-top: 5px;

    .folderTreeFolderStyling1 {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 300px;
      cursor: pointer;
      color: white;
      background-color: #313035;
    }

    .folderTreeFolderStyling2 {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 300px;
      cursor: pointer;
      color: white;
    }

    .folderTreeInputField1 {
      display: block;
      padding-left: 25px;
    }

    .folderTreeInputField2 {
      display: none;
      padding-left: 25px;
    }

    .folderTreeFileStyling1 {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      color: white;
      cursor: pointer;
      background-color: #313035;
    }

    .folderTreeFileStyling2 {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      color: white;
      cursor: pointer;
    }

    .folderTreeRenameInputField {
      padding-left: 0px;
    }
  }

  .folderTreeMenuDrawer {
    background-color: #262625;
    padding: 10px 15px 10px 15px;
    border: 1px solid #545453;
    border-radius: 2px;
    width: 12rem;

    .folderTreeMenuDrawerItem {
      cursor: pointer;
      color: white;
      margin-bottom: 5px;
    }
  }

  .codeEditorButton {
    margin: 15px 0px 15px 0px;
    justify-content: end;
  }
}
