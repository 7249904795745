/* 
* Green tick Conatiner styling
*/
.dataCardGreenTickContainer {
  height: 30px;
  padding: 16px 16px 0px 0px;
  display: flex;
  justify-content: flex-end;

  /* 
    * Green tick icon styling
    */
  .dataCardGreenTick {
    display: flex;
    justify-content: flex-end;
    height: 20px;
    width: 20px;
  }
}

/* 
    * Data Card Icon Container styling
    */
.dataCardMainIconContainer {
  display: flex;
  justify-content: center;
  padding: 15px;

  /* 
* Avatar Icon styling
*/
  .dataCardAvatar {
    display: flex;
    justify-content: center;
    padding-top: 35px;
  }
}

/* 
* Data card name styling
*/
.dataCardText {
  display: flex;
  justify-content: center;
}
